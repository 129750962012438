import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import axios from "axios";

import OrgAuth from "./routing/OrgAuth";
import StudentAuth from "./routing/StudentAuth";
import OwnerAuth from "./routing/OwnerAuth";

import "./App.css";
import "../src/assets/css/home.css";

/** public pages start */
import SignInOrgID from "../src/pages/authentication/SignInOrgID";
import SignInOrgEmail from "../src/pages/authentication/SignInOrgEmail";
import SignIn from "../src/pages/authentication/SignIn";
import ForgotPassword from "../src/pages/authentication/ForgotPassword";
import Otp from "../src/pages/authentication/Otp";
import ResetPassword from "../src/pages/authentication/ResetPassword";
import TokenBasedSignIn from "../src/pages/authentication/TokenBasedSignIn";
import SignInAddPhoneNumber from "../src/pages/authentication/SignInAddPhoneNumber";
import AddEmailAddress from "../src/pages/authentication/AddEmailAddress";
import ChangeEmailAddress from "../src/pages/authentication/ChangeEmailAddress";

/** public pages end */

/** org pages start */
import Courses from "../src/pages/org-portal/courses/CourseListing";
import AddCourse from "../src/pages/org-portal/courses/add/add";
import DuplicateCourse from "../src/pages/org-portal/courses/duplicate/duplicate";
import CourseDetails from "../src/pages/org-portal/courses/details/DetailsPage";

import UserListing from "../src/pages/org-portal/users/UserListing";
import AddUser from "../src/pages/org-portal/users/addUser/addUser";
import UsersDetails from "../src/pages/org-portal/users/details/DetailsPage";

import TracksListing from "../src/pages/org-portal/tracks/TracksListing";
import TracksDetails from "../src/pages/org-portal/tracks/details/DetailsPage";

import CategoriesListing from "../src/pages/org-portal/categories/CategoriesListing";
import FeaturedListing from "../src/pages/org-portal/featured/FeaturedListing";

import TeamMembers from "./pages/org-portal/team/member/TeamMembers";
import RolesPermissions from "./pages/org-portal/team/rolespermissions/RolesPermissions";
import TeamDetails from "../src/pages/org-portal/team/member/DetailsPage";

import Coupons from "../src/pages/org-portal/coupons/Coupons";

/** org pages end */

import TableData from "../src/components/Table/TableData";

/** student pages start */
import MyLearning from "./pages/student-portal/courses/MyLearning";
import StudentCourseDetails from "../src/pages/student-portal/courses/details/DetailsPage";
import StudentsSettings from "../src/pages/student-portal/settings/SettingsPage";
import Calendar from "../src/pages/student-portal/calendar/Calendar";
import Certificates from "../src/pages/student-portal/certificates/Certificates";
import CertificatesDetails from "./pages/student-portal/certificates/CertificatesDetails";
import MediaLibrary from "../src/components/MediaLibrary/MediaLibrary";
import MediaLibraryTableView from "../src/components/MediaLibrary/MediaLibraryTableView";
import TrackDetails from "./pages/student-portal/courses/details/TrackDetails";
import StudentHome from "../src/pages/student-portal/studentHome/StudentHome";
import Inbox from "./pages/student-portal/inbox/pages/Inbox";
import InboxMessage from "./pages/student-portal/inbox/pages/Message";
import InboxAnnouncement from "./pages/student-portal/inbox/pages/Announcement";
import InboxEmail from "./pages/student-portal/inbox/pages/Email";
/** student pages end */

/** owner pages start */
import OwnerCustomerListing from "../src/pages/owner-portal/OwnerCustomerListing";
import OwnerCustomerAddNew from "../src/pages/owner-portal/customers/add/Add";
import OwnerDetailsPage from "../src/pages/owner-portal/customers/details/DetailsPage";
import EmptyPage from "./components/EmptyPage/EmptyPage";
import ResetPasswordVisibleEmail from "./pages/authentication/ResetPasswordVisibleEmail";

/** owner pages end */

function App() {
  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
  const applicationStatus = process.env.REACT_APP_PROJECT_ENV;
  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  //================== google recapcha v3 part end ====================//

  //===================================== fetach data =======================================//
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgId = localStorage.getItem("orgId");
  const [
    instructorByOrgAndUserIdInfoStatus,
    setInstructorByOrgAndUserIdInfoStatus,
  ] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(true);

  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const getInstructorByOrgAndUserId = () => {
    let data = {
      orgId: orgId,
      userId: userData?.id,
    };
    axios
      .post(`${baseUrl}/orgPortal/getInstructorByOrgAndUserId`, data)
      .then((result) => {
        setLoadingStatus(false);
        setInstructorByOrgAndUserIdInfoStatus(result?.data?.body?.status);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (orgId && userData?.id) {
      getInstructorByOrgAndUserId();
    }
  }, [orgId, userData?.id]);

  //===================================== fetach data =======================================//

  const handleChange = () => {
    localStorage.setItem("activePortal", 0);
    localStorage.removeItem("orgId");
    localStorage.removeItem("subDomain");
    userData.userRole = "student";
    localStorage.setItem("userData", JSON.stringify(userData));
    navigate("/my-courses", { replace: true });
  };

  return (
    <Routes>
      {/* public routes start */}
      <Route path="/" element={<SignIn />} />
      <Route path="/singin-org-id" element={<SignInOrgID />} />
      <Route path="/singin-org-email" element={<SignInOrgEmail />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/verify-otp" element={<Otp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/token-based-signin" element={<TokenBasedSignIn />} />
      <Route path="/add-phone-number" element={<SignInAddPhoneNumber />} />
      <Route path="/add-email-address" element={<AddEmailAddress />} />
      <Route path="/change-email-address" element={<ChangeEmailAddress />} />
      <Route
        path="/teacher-new-password"
        element={<ResetPasswordVisibleEmail />}
      />
      {/* public routes end */}

      {/* org routes start */}
      <Route
        path="/courses-listing"
        element={
          <OrgAuth>
            {!loadingStatus && (
              <>
                {" "}
                {instructorByOrgAndUserIdInfoStatus === "active" ? (
                  <Courses />
                ) : (
                  <EmptyPage
                    pageHeading="Page not found"
                    pageSubtitle="Either this page doesn't exist or you don't have permission to access it."
                    showButton={true}
                    btnLabel="Back to learner portal"
                    // btnLink={"/my-coursesList"}
                    onClick={handleChange}
                  />
                )}
              </>
            )}
          </OrgAuth>
        }
      />

      <Route
        path="/add-courses/:courseId"
        element={
          <OrgAuth collapsed={true}>
            <AddCourse />
          </OrgAuth>
        }
      />
      <Route
        path="/duplicate-course/:courseId"
        element={
          <OrgAuth collapsed={true}>
            <DuplicateCourse />
          </OrgAuth>
        }
      />
      <Route
        path="/media-library-table-view"
        element={
          <OrgAuth>
            <MediaLibraryTableView />
          </OrgAuth>
        }
      />
      <Route
        path="/course-details/:courseId"
        element={
          <OrgAuth collapsed={true}>
            <CourseDetails />
          </OrgAuth>
        }
      />
      <Route
        path="/users"
        element={
          <OrgAuth>
            <UserListing />
          </OrgAuth>
        }
      />
      <Route
        path="/add-student"
        element={
          <OrgAuth collapsed={true}>
            <AddUser />
          </OrgAuth>
        }
      />
      <Route
        path="/student-details/:userId"
        element={
          <OrgAuth collapsed={true}>
            <UsersDetails />
          </OrgAuth>
        }
      />

      <Route
        path="/tracks"
        element={
          <OrgAuth>
            <TracksListing />
          </OrgAuth>
        }
      />
      <Route
        path="/track-details/:trackId"
        element={
          <OrgAuth collapsed={true}>
            <TracksDetails />
          </OrgAuth>
        }
      />
      <Route
        path="/categories-listing"
        element={
          <OrgAuth>
            <CategoriesListing />
          </OrgAuth>
        }
      />
      <Route
        path="/featured-listing"
        element={
          <OrgAuth>
            <FeaturedListing />
          </OrgAuth>
        }
      />

      <Route
        path="/team-listing"
        element={
          <OrgAuth>
            <TeamMembers />
          </OrgAuth>
        }
      />

      <Route
        path="/roles-permissions"
        element={
          <OrgAuth>
            <RolesPermissions />
          </OrgAuth>
        }
      />

      <Route
        path="/team-details/:userData"
        element={
          <OrgAuth collapsed={true}>
            <TeamDetails />
          </OrgAuth>
        }
      />

      <Route
        path="/inbox"
        element={
          <OrgAuth
            collapsed={true}
            BodyFullWidth="BodyFullWidth"
            BgColor="#fff"
          >
            <Inbox />
          </OrgAuth>
        }
      />

      <Route
        path="/coupons"
        element={
          <OrgAuth>
            <Coupons />
          </OrgAuth>
        }
      />

      <Route path="/table" element={<TableData />} />

      {/* org routes end */}

      {/* student routes start */}
      <Route
        path="/my-courses"
        element={
          <StudentAuth>
            <MyLearning />
          </StudentAuth>
        }
      />

      <Route
        path="/my-course-details/:courseId"
        element={
          <StudentAuth collapsed={true}>
            <StudentCourseDetails />
          </StudentAuth>
        }
      />
      <Route
        path="/settings"
        element={
          <StudentAuth collapsed={true}>
            <StudentsSettings />
          </StudentAuth>
        }
      />
      <Route
        path="/calendar"
        element={
          <StudentAuth>
            <Calendar />
          </StudentAuth>
        }
      />
      <Route
        path="/certificates"
        element={
          <StudentAuth>
            <Certificates />
          </StudentAuth>
        }
      />
      <Route
        path="/certificate-details/:userId/:id"
        element={
          <StudentAuth>
            <CertificatesDetails />
          </StudentAuth>
        }
      />
      <Route
        path="/media-library"
        element={
          <StudentAuth>
            <MediaLibrary />
          </StudentAuth>
        }
      />

      <Route
        path="/my-track-details/:trackId"
        element={
          <StudentAuth>
            <TrackDetails />
          </StudentAuth>
        }
      />
      <Route
        path="/student-home"
        element={
          <StudentAuth>
            <StudentHome />
          </StudentAuth>
        }
      />

      <Route
        path="/inbox-student"
        element={
          <StudentAuth
            collapsed={true}
            BodyFullWidth="BodyFullWidth"
            BgColor="#fff"
          >
            <Inbox />
          </StudentAuth>
        }
      />

      <Route path="/inbox-type/:type" element={<InboxMessage />} />

      {/* student routes end */}

      {/* owner routes start */}
      <Route
        path="/customer-listing"
        element={
          <OwnerAuth>
            <OwnerCustomerListing />
          </OwnerAuth>
        }
      />
      <Route
        path="/add-new-customer"
        element={
          <OwnerAuth>
            <OwnerCustomerAddNew />
          </OwnerAuth>
        }
      />
      {/* <Route path="/customer-details/:customerId" element={<OwnerAuth><OwnerDetailsPage /></OwnerAuth>} /> */}
      <Route
        path="/customer-details"
        element={
          <OwnerAuth>
            <OwnerDetailsPage />
          </OwnerAuth>
        }
      />
      {/* owner routes end */}
    </Routes>
  );
}

export default App;
