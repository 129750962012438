import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";

import CustomizedSnackbar from "../../hooks/Snackbar";
import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import PasswordStrength from "../../components/Form/FieldPassword/PasswordStrength";
import FieldPassword from "../../components/Form/FieldPassword/FieldPassword";
import Header from "./header/Header";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import "./Form.css";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";

function ResetPasswordVisibleEmail(props) {
  const baseUrl = process.env.REACT_APP_PROJECT_ENV === "prod" ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
  // Get the location object
  const location = useLocation();

  // Function to parse the query string
  const queryString = new URLSearchParams(location.search);
  
  let [searchParams, setSearchParams] = useSearchParams();
  let userToken = searchParams.get("token");
  let userDataObject = {}
  
  atob(userToken).split('&').map((data) => {
    let dataWithKeyVal = data.split('=')
    userDataObject[dataWithKeyVal[0]] = dataWithKeyVal[1]
  })

  let fullHost = window.location.hostname;
  let splittedHost = fullHost.split(".");
  let subDomain = splittedHost[0];

  const takbirUrl =
  subDomain == "stage-admin"
    ? "https://main-stage.mytakbir.com/search"
    : "https://mytakbir.com/search";

  let projectSecondaryEnv = process.env.REACT_APP_TAKBIR_SECONDARY_ENV;
  let mainWebsiteUrl = process.env.REACT_APP_TAKBIR_SUBDOMAIN;


  //====================create a link to targetPage courses =======================//
  if (projectSecondaryEnv == "prod") {
    mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain.", "");
  } else {
    const hasLocalhost = mainWebsiteUrl.includes("localhost");

    if (!hasLocalhost) {
      if (projectSecondaryEnv == "dev") {
        mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain", "main-dev");
      } else if (projectSecondaryEnv == "stage") {
        mainWebsiteUrl = mainWebsiteUrl.replace("sub-domain", "main-stage");
      }
    }
  }
  
  //====================create a link to targetPage courses =======================//



  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");

  const handleChanges = (fieldName) => (event) => {
    const fieldValue = event.target.value;
    setConfirmPassword(fieldValue);
  };

  const processResponse = (response) => {
    
    if (response.data.body.status == true) {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.body.message);
      // redirect to sign in page
      setTimeout(() => {
        setSnackbarMessage("Redirecting to sign in page...");
        window.location.replace(`${window.location.origin}/signin`);
      }, 3000);
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.body.message);
    }
  };

  const sendResetPasswordRequest = () => {


    if (passwordValidation == false) {
      toast.error("Password should have matched all the below criteria")
      return false;
    } else if (password !== confirmPassword) {
      toast.error("Password is not matching")
      return false;
    } else {
      signUpHandler(userDataObject)
    
    }
  };

  const checkAlreadyGenerated = async () => {
    
    axios.post(
      `${baseUrl}/checkUserEmailExist`,
      {
        email : userDataObject?.email
      }
    ).then((response) => {
      // console.log(response.data.message)
    }).catch((err) => {
      if(err.status == 409){
        var signinToken = CryptoJS.AES.encrypt(
          JSON.stringify({
            email: userDataObject.email,
            phone_number: userDataObject.phone,
            userToken: err.response.data.token,
          }),
          "token-based-signin-aglbnd-854256-secret-key"
        ).toString();
        
        window.location.replace(mainWebsiteUrl += `?signinToken=${signinToken}&targetPage=create-new-teaching-account`);
      }
      toast.error(err.response.data.message)
    })


  }

  useEffect(() => {
    checkAlreadyGenerated()
    
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_PUBLIC_API_URL_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
    
  }, []);

  

  /**
   *
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };


  const signUpHandler = async (postData) => {
    

    postData["password"] = password;


    if (postData.password == "") {
      // show validation error message
      toast.error("Password is required")
      return false;
    } else if (passwordValidation == false) {
      // show password validation error message
      toast.error("Password should have matched all the below criteria.")
      return false;
    }
    ///===================== recapcha ==============================///
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(
          `${process.env.REACT_APP_PUBLIC_API_URL_GOOGLE_RECAPTCHA_SITE_KEY}`,
          { action: "submit" }
        );
        ///===================== recapcha ==============================///
        // add aditional data
        postData["user_role"] = "student";
        postData["password_confirmation"] = password;
        postData["sign_up_with"] = 'email';
        postData["phone_number"] = postData?.phone;
        postData["first_name"] = postData?.first_name;
        postData["last_name"] = postData?.last_name;
        postData["email"] = postData?.email;
        postData["token"] = token;

        axios
          .post(`${baseUrl}/register`, postData)
          .then((response) => processStudentSignUp(response))
          .catch((err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message)
              
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }
    });
  };

  const processStudentSignUp = (result) => {

    const response = result.data;
    if (response?.error) {
      toast.error(response?.message)
    } else {
      const userData = {
        id: response.body.id,
        firstName: response.body.first_name,
        lastName: response.body.last_name,
        email: response.body.email,
        phone_number: response.body.phone_number,
        image: response.body.image,
        userRole: response.body.user_role,
      };
      toast.success('Sign Up Success!')

      var signinToken = CryptoJS.AES.encrypt(
        JSON.stringify({
          email: userData.email,
          phone_number: userData.phone_number,
          userToken: response.token,
        }),
        "token-based-signin-aglbnd-854256-secret-key"
      ).toString();

      window.location.replace(mainWebsiteUrl += `?signinToken=${signinToken}&targetPage=create-new-teaching-account`);
    }
  };



  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <CustomizedSnackbar
            open={showSnackbar}
            severity={snackbarSeverity}
            message={snackbarMessage}
            handleClose={handleSnackbarClose}
          />
          <div className="SignForm">
            <h1>Setup New Password</h1>
            <p>
              creating password for:&nbsp;<span>{userDataObject?.email}</span>
            </p>

            <div className="SignFormGrid">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <PasswordStrength
                    placeholder="New Password"
                    handlePasswordText={setPassword}
                    handlePasswordValidation={setPasswordValidation}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <FieldPassword
                    placeholder="Confirm New Password"
                    handleChange={handleChanges("confirmPassword")}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Buttons
                    label="Create Password"
                    buttonColor="#004FE0"
                    border="#ccc 1px solid"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    link=""
                    onClick={sendResetPasswordRequest}
                  />
                  <div className="BottomButton">
                    <Buttons
                      label="Cancel"
                      color="#004FE0"
                      width=""
                      height="48px"
                      fontSize="14px"
                      link="/signin"
                    />
                  </div>
                  <div>
                    <p>
                      <span>Not you?</span>&nbsp;
                      <a href="/">Log out</a>
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="SignFormFooter">
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ResetPasswordVisibleEmail;
